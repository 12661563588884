@charset "utf-8";

@import '../../variables/variables.less';
@import '../../style-guide/components/vue-universal-actionbox.less';
@import '../../style-guide/components/frequently-asked.less';
@import '../../components/facelift/animatedGraphs.less';

.bg-gray {
	background-color: @White-v2;
}

.link-underline,
.link-underline:hover {
	text-transform: none;
	border-bottom: 2px dashed #2276d9;
	color: #2276d9;
	text-decoration: none;
}

// collapse toggler styles
.collapse-toggler-wrap {
	position: relative;
	.icon {
		display: inline-block;
		width: 2rem;
		height: 1rem;
		padding-left: 0.5rem;
		&::after,
		&::before {
			content: '';
			display: inline-block;
			width: 1rem;
			height: 1rem;
			position: absolute;
			transition: all linear 0.3s;
			top: 0;
		}
		&::after {
			background: url(../../../i/svgs/16x16/ico-plus.svg) no-repeat;
		}
		&::before {
			background: url(../../../i/svgs/16x16/ico-minus.svg) no-repeat;
			opacity: 0;
		}
	}
	a.collapse-toggler {
		display: inline-block;
    border-bottom: 2px dashed @Blue-v2;
    color: @Blue-v2;
    font-weight: 500;
    text-decoration: none;

		&.minus {
			.icon::after {
				transform: rotateZ(180deg);
				opacity: 0;
			}
			.icon::before {
				opacity: 1;
				transform: rotateZ(180deg);
			}
		}
	}
}
.collapse-content {
	margin-top: 2rem;
	ul {
		margin-bottom: 0;
		li {
			padding-left: 2.5rem;
			position: relative;
			&::before {
				content: '';
				display: inline-block;
				background: url(../../../i/svgs/24x24/ico-bullet-right.svg)
					no-repeat;
				position: absolute;
				width: 1.5rem;
				height: 1.5rem;
				top: 0;
				left: 0;
			}
		}
	}
}

.button {
	&.primary {
		&.green {
			&.outline {
				&.icon-right {
					span {
						&::after {
							margin-left: 1rem;
							content: url(../../../i/page/avg-pctuneup-v2/icon-arrow-right.svg);
						}
						&:hover {
							&::after {
								content: url(../../../i/page/avg-pctuneup-v2/icon-arrow-right-white.svg);
							}
						}
					}
				}
			}
		}
	}
}
.button-wrapper {
	@media @mobile {
		text-align: center;
	}
}

.hero {
	background-image: url(../../../i/page/avg-pctuneup-v2/hero-v2.jpg);
	background-size: cover;

	.container {
		padding-top: 4rem;
		padding-bottom: 5.5rem;
	}
	&__content {
		.actionboxes-list {
			display: flex;
			flex-wrap: wrap;
			align-content: center;
		}
	}
	&__headline {
		color: @White;
		font-size: 3.5rem;
		line-height: 4rem;
	}
	&__subheadline {
		color: @White;
	}

	.trustpilot-widget {
		margin: 2.5rem 0 3.75rem;
		height: 40px;

		@media @tablet, @desktop {
			height: 20px;
		}
	}

	.combined-actionbox {
		margin-bottom: 1.5rem;
	}

	.hero-hint {
		color: @White;
		display: flex;
		justify-content: center;
		gap: 2rem;
		flex-wrap: wrap;

    a {
      color: @White;
      font-weight: 500;
      border-bottom: 2px dashed @White;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
	}
}

.automatic-maintenance,
.speedup,
.bloatware,
.get-more-room {
  a {
    color: @Grey-1;
    text-decoration: underline;
  }
}

#features {
	padding: 5.5rem 0;
	.features__wrapper {
		margin-top: 4rem;
		@media @mobile, @tablet {
		}
		.features__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			@media @mobile {
				padding: 1rem 0;
				border-bottom: 1px solid ~'#747a8733';
				flex-direction: row;
				text-align: inherit;
				&.last {
					border-bottom: 0;
				}
			}
			img {
				margin-bottom: 1rem;
				@media @mobile {
					margin-bottom: 0;
					margin-right: 1rem;
					&:dir(rtl) {
						margin-right: 0;
						margin-left: 1rem;
					}
				}
			}
		}
	}
}

#automatic-maintenance,
#get-more-room {
	.container {
		padding-top: 5.5rem;
		padding-bottom: 6rem;
		.media-wrapper {
			margin-top: 3rem;
			.img {
				img {
					@media @desktop {
						padding: 0 1rem;
					}
				}
				@media @mobile {
					margin-top: 4.5rem;
				}
			}
			.text {
				&-wrapper {
					position: relative;
					padding-left: 6rem;
					margin-bottom: 3rem;
					&:last-of-type {
						margin-bottom: 0;
					}
					img {
						position: absolute;
						left: 0;
						top: 0;
					}
					h5 {
						margin-bottom: 1rem;
					}
					p {
						margin: 0;
					}
					@media @mobile {
						padding-left: 0;
						text-align: center;
						img {
							position: relative;
						}
					}
				}
			}
		}
	}
}

#speedup {
	padding: 5.5rem 0;

	.text-wrapper {
		@media @mobile {
			padding-bottom: 4.5rem;
		}
		h2 {
			@media @mobile {
				text-align: center;
			}
		}
	}
	.media-wrapper {
		margin-top: 1.5rem;
		.img {
			@media @mobile {
				text-align: center;
			}
		}
	}
}

#bloatware,
#software-updater {
	.container {
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}
	.text-wrapper {
		@media @mobile {
			padding-bottom: 4.5rem;
		}
		h2 {
			@media @mobile {
				text-align: center;
			}
		}
	}
	.media-wrapper {
		margin-top: 1.5rem;
		.img {
			text-align: left;
			@media @mobile {
				text-align: center;
			}
		}
	}
}

#software-updater {
	.software-updater {
		.text-wrapper {
			@media @mobile {
				text-align: center;
			}
		}
	}
}

// System req
#requirements {
	.container {
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}
	hr {
		border-color: #dbdfe5;
		border-top: 0;
		margin: 2rem 0;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		li {
			max-width: 200px;
			margin-right: 2rem;
			img {
				display: block;
				margin-bottom: 1rem;
			}
		}
		@media @mobile {
			li {
				position: relative;
				padding-left: 3rem;
				max-width: 100%;
				img {
					display: inline-block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}

#faq {
	.container {
		padding-bottom: 5.5rem;
		h2 {
			margin-bottom: 4rem;
		}
	}
	.faq-container {
		position: relative;
		z-index: 10;
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
		background-color: @White;
		padding-top: 15px;
		padding-bottom: 15px;
		.accordion-item {
			border-bottom: 1px solid #dbdfe5;
			&:last-child {
				border-bottom: none;
			}
		}
		// question
		.question {
			position: relative;
			cursor: pointer;

			@media @tablet, @desktop {
				padding: 39px 128px 39px 48px;
			}
			@media @mobile {
				padding: 20px 63px 20px 24px;
			}

			&:not(:first-child) {
				border-top: 1px solid @Grey-5;
			}

			// clicker
			&::after {
				position: absolute;
				content: '';
				display: inline-block;
				border-radius: 5px;
				border: 1px solid @Grey-4;
				background-color: transparent;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url(../../../i/ico/ico-plus-blue.svg);

				@media @tablet, @desktop {
					top: 24px;
					right: 32px;
					width: 56px;
					height: 56px;
				}
				@media @mobile {
					top: 16px;
					right: 16px;
					width: 32px;
					height: 32px;
				}
			}
			&:hover:not(.expand)::after {
				background-color: @Green;
				border: none;
				background-image: url(../../../i/ico/ico-plus-white.svg);
			}
			&.expand::after {
				background-color: transparent;
				background-image: url(../../../i/ico/ico-close-blue.svg);
			}
			&.expand:hover::after {
				background-color: @Green;
				border: none;
				background-image: url(../../../i/ico/ico-close-white.svg);
			}
		}

		// answer
		.answer {
			overflow: hidden;
			transition: max-height 0.3s ease-out; // note that we're transitioning max-height
			height: auto;
			max-height: 0;
			overflow: hidden;

			@media @tablet, @desktop {
				padding: 0 128px 0 80px;
			}
			@media @mobile {
				padding: 0 64px 0 24px;
			}

			h5,
			li,
			p {
				@media @mobile {
					font-size: 12px;
					line-height: 18px;
				}
			}
			h5 {
				margin-bottom: 0;
			}
			ol {
				padding-inline-start: 40px;
			}
			ul {
				li {
					padding-bottom: 0;
					padding-left: 40px;
					position: relative;
					&:before {
						content: '·';
						display: inline-block;
						width: auto;
						height: auto;
						color: @Green;
						position: absolute;
						left: 20px;
						font-size: 2.5rem;
						line-height: 23px;
						@media @mobile {
							line-height: 17px;
						}
					}
				}
			}

			p:last-child {
				@media @tablet, @desktop {
					margin-bottom: 39px;
				}
				@media @mobile {
					margin-bottom: 16px;
				}
			}
		}
		// expand
		div.expand + div {
			max-height: 600px;
		}
	}
}

#numbers {
	padding: 5.5rem 0;
	.row {
		&-header {
		}
	}
}

// before footer
#bft {
	.container {
		background-image: url(../../../i/facelift/components/bft/before-footer-triangle.svg);
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: auto;
		> .row {
			padding: 6rem 0 4rem;
		}
	}
	h2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		img {
			margin-right: 1rem;
		}
		& + p {
			margin-bottom: 4rem;
		}
		@media @mobile, @tablet {
			flex-direction: column;
			img {
				margin: 0 auto 1rem auto;
				max-width: 50px;
			}
		}
	}

  .vue-action-box {
    flex-basis: 33.33333332%;
    padding: 0 20px;
  }
}

#blogposts {
	padding-top: 88px;
	padding-bottom: 64px;
	@media @mobile {
		padding-top: 64px;
	}

	.button.secondary > span {
		font-weight: 500;
	}

	.container {
		//overflow-x: hidden;
		padding-bottom: 56px;
		@media @mobile {
			padding-bottom: 48px;
		}
	}

	.post {
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	.row {
		&.blog {
			justify-content: center;
		}
	}

	.carousel-slider {
		.container {
			@media @mobile, @tablet {
				padding-top: 0;
			}
		}
	}

	h2 {
		text-align: left;
		margin-bottom: 0;
		@media @mobile {
			margin-bottom: 24px;
		}
	}
	h4 {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid @Grey-12;

		@media @mobile, @tablet {
			font-size: 16px;
		}
	}
	img {
		width: 100%;

		@media @mobile, @tablet {
			margin-bottom: 16px;
		}
	}

	p {
		color: @Grey-1;
		text-decoration: none;
		font-size: 14px;
	}

	a {
		&:hover {
			text-decoration: none;
		}

		&.button {
			&.secondary {
				span {
					border-bottom: 2px dashed @Blue-v2;
				}
			}
		}
	}

	.link-all {
		margin-bottom: 0;
		@media @tablet, @desktop {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding-bottom: 16px;
		}

		a {
			border-bottom: none;
			&.button {
				display: flex;
				justify-content: flex-end;

				@media @mobile {
					justify-content: flex-start;
				}
			}
		}
	}
}

.product-icon {
	margin-bottom: 28px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	h1 {
		margin-bottom: 0;
	}

	img {
		margin-right: 24px;
	}

	@media @mobile {
		flex-direction: column;
		margin-bottom: 16px;

		img {
			margin-right: 0;
			margin-bottom: 8px;
		}
	}
}

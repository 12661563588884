/* Animated graphs
* 	Requires avm.require('avast.web.waypoints')
* 	for adding '.animated' class into '.js-animatedGraph' element.
*	Please note: Set heights of column rectangles elements inline i.e 'style="height:235px;"'
*/

@import '../../variables/variables.less';

@colorGray: #DBDEE4;

.animatedGraph {
	height: 100%;
	display: flex;
	flex-direction: column;
	&-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: @Blue;
		margin-bottom: 4rem;
		&-title {
			font-size: 3.5rem;
			line-height: 4rem;
			font-weight: bold;
			text-align: center;
		}
		&-text {
			text-align: center;
			font-size: 1.25rem;
		}
		@media @mobile {
			order: -1;
			align-items: flex-start;
			margin-bottom: 1rem;
		}
	}
	&-columns {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
		min-height: 320px;
		height: 100%;
		margin: auto auto 0 auto;
		@media @mobile {
			width: 100%;
			flex-direction: column-reverse;
			min-height: 100px;
		}

		width: 200px;
		&-left, &-right {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			@media @mobile {
				flex-direction: row-reverse;
			}
			.value {
				margin-bottom: 0.5rem;
				font-weight: bold;
				@media @mobile {
					margin: auto 0;
					padding: 0 1rem;
					width: 5rem;
					text-align: center;
				}
			}
			.rectangle {
				width: 100%;
				transition: all 2s cubic-bezier(0.4, 0, 0.22, 0.72);
				max-height: 0%;
				@media @desktop, @tablet {
					width: 100%!important;
					max-width: 100%!important;
				}
				@media @mobile {
					max-width: 0;
					width: ~'calc(100% - 4rem)';
					height: 48px!important;
				}
			}
		}
		&-left {
			.value {
				color: @Grey-2;
			}
			.rectangle {
				background-color: @colorGray;
			}
		}
		&-right {
			.value {
				color: @Blue;
			}
			.rectangle {
				background: linear-gradient(180deg, #2A7DE1 0%, #24ACDB 100%);
			}
		}
	}
	&-bottom {
		text-align: center;
		margin-top: 1rem;
		color: @Grey-2;
		@media @mobile {
			text-align: left;
			order: -1;
			margin-top: 0;
			margin-bottom: 1rem;
		}
	}
	@media @mobile {
		margin-bottom: 3rem;
	}
	&.js-animatedGraph {
		&.animated {
			.animatedGraph-columns .rectangle {
				max-height: 100%;
				@media @mobile {
					max-width: ~'calc(100% - 5rem)';
				}
			}
		}
	}
}


